.custom-image-upload {
  #fileMedia {
    display: none;
  }
  > label {
    display: inline-flex;
    cursor: pointer;
    margin: 0 !important;
    border: 1px dashed #999999;
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    > img {
      width: 100%;
    }
  }
}
