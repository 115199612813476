.profile-container {
  background-color: #f0f0f0;
  min-height: calc(100vh - 80px);
  .btn-submit-custom {
    display: flex;
    background-color: #1dbd1d;
    color: #ffffff;
    padding: 17px 38px;
    border-radius: 9px;
    margin: 0 auto;
  }
  &__wrapper {
    max-width: 1440px;
    width: 100%;
    padding: 50px 0;
    margin: 0 auto;
    display: flex;
    .section-left,
    .section-right {
      flex: 50%;
      h5 {
        margin-bottom: 20px;
        font-family: Montserrat-Bold;
        font-weight: 600;
        color: #000000;
      }
      &:not(.section-left) {
        padding-left: 18px;
        .setting-secure,
        .setting-notice {
          border-radius: 25px;
          box-shadow: 0 5px 26px 6px rgba(0, 0, 0, 0.05);
          background-color: #ffffff;
          padding: 35px;
          margin-bottom: 40px;
          &--top,
          &--bottom {
            display: flex;
            &:not(.setting-secure--bottom) {
              margin-bottom: 35px;
            }
            .setting-secure-method {
              flex: 50%;
              > p {
                font-size: 18px;
              }
              > button {
                border-radius: 9px;
                border: solid 1px #1dbd1d;
                color: #1dbd1d;
                font-weight: 600;
                max-width: 260px;
                width: 100%;
                padding: 10px;
                &.auth-gg {
                  color: #4abcaf;
                  border: solid 1px #4abcaf;
                }
              }
              .method--wrapper {
                display: inline-block;
              }
            }
          }
          &--bottom {
            .setting-secure-method {
              .method-group {
                display: flex;
                justify-content: space-between;
                margin-bottom: 35px;
                align-items: center;
                .form-group-check {
                  user-select: none;
                  > label {
                    border-radius: 9px;
                    border: solid 1px #8c8c8c;
                    padding: 12px 19px;
                    > img {
                      width: 10%;
                    }
                  }
                  .linked-for-me {
                    &::after {
                      background: url("../images/google-icon.svg") no-repeat
                        center center;
                      background-size: 20px 20px;
                      display: inline-block;
                      width: 20px;
                      height: 20px;
                      content: "";
                      margin-left: 10px;
                    }
                  }
                  .telegram::after {
                    background: url("../images/telegram-2019-logo.svg")
                      no-repeat center center;
                    background-size: 20px 20px;
                  }
                }
              }
            }
          }
          .btn-submit-custom--notice {
            padding: 15px 38px;
          }
          .notice-config-group {
            display: flex;
          }
        }
      }
      &:not(.section-right) {
        padding-right: 18px;
        .profile-info {
          border-radius: 25px;
          box-shadow: 0 5px 26px 6px rgba(0, 0, 0, 0.05);
          background-color: #ffffff;
          padding: 35px;
          &__head {
            display: flex;
            margin-bottom: 34px;
          }
          &__image {
            max-width: 135px;
            max-height: 135px;
            width: 100vw;
            height: 100vh;
            overflow: hidden;
            border-radius: 25px;
            margin-right: 24px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          &__content {
            .profile-info__name {
              font-family: Montserrat-Bold;
              font-size: 24px;
              margin-bottom: 15px;
            }
            .profile-info__upload {
              border-radius: 9px;
              border: solid 1px #1dbd1d;
              width: max-content;
              > label {
                font-size: 15px;
                color: #1dbd1d;
                padding: 13px 10px;
                cursor: pointer;
              }
              > input {
                display: none;
              }
            }
          }

          &__body {
            .form-profile {
              .form-group {
                margin-top: 14px;
                background-color: #f0f0f0;
                padding: 11px 17px;
                border-radius: 11px;
                &--disable {
                  opacity: 0.8;
                }
                > label {
                  color: #8c8c8c;
                  font-size: 12px;
                }
                > input,
                select {
                  font-size: 18px;
                  border: none;
                  background-color: #f0f0f0;
                  padding: 0;
                  color: #383838;
                  border-radius: 0;
                  border-bottom: 1px solid transparent;

                  &:focus {
                    border-bottom: 1px solid;
                    outline: none;
                    box-shadow: none;
                  }
                }
              }
              .group-button {
                margin-top: 38px;
                > button {
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
