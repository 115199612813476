.modal-dialog {
  margin-top: 5.75rem;
  max-width: 783px;
  width: 100%;
  .modal-content {
    border-radius: 25px;
    box-shadow: 0 2px 16px 7px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    overflow: hidden;
    border: none;
    .modal-header {
      padding: 24px 0;
      padding-left: 30px;
      position: relative;
      background: #04a905;
      .modal-title {
        font-family: Montserrat-SemiBold;
        color: #ffffff;
      }
      .btn-close-modal {
        position: absolute;
        top: 0;
        right: 5px;
        color: #ffffff;
        font-size: 22px;
      }
    }
    .modal-body {
      padding: 37px 30px;
      .form-confirm {
        display: flex;
        .form-group {
          width: 100%;
        }
        input {
          padding: 16px 19px;
          border-radius: 11px;
          border: solid 0.3px #c5c4c4;
          font-size: 14px;
          &::placeholder {
            color: #737373;
            opacity: 0.6;
          }
        }
        .btn-confirm {
          background: #04a905;
          color: #ffffff;
          font-size: 14px;
          padding: 16px 19px;
          border-radius: 11px;
          max-width: 160px;
          width: 100%;
          margin-left: 26px;
          height: max-content;
        }
      }
    }
  }
}

.add-app-modal {
  max-width: 481px;
  margin-top: 3.75rem;
  .modal-header {
    padding: 20.5px 0 !important;
    padding-left: 30px !important;
    .modal-title {
      font-size: 20px;
    }
  }
  .modal-content {
    .modal-body {
      padding: 26px 34px;
      form {
        .btn-group {
          justify-content: center;
        }
        .form-group {
          margin-bottom: 20px;
          label {
            font-family: Montserrat-SemiBold;
            font-size: 14px;
            margin-bottom: 10px;
          }
          input,
          select,
          textarea {
            border-radius: 11px;
            border: solid 0.3px #97979777;
            font-size: 14px;
            &:not(select)::placeholder {
              color: #666666;
            }
          }
          .btn-form-custom {
            border-radius: 11px;
            box-shadow: 0 7px 14px -5px rgba(16, 247, 21, 0.5);
            background-color: #1dbd1d;
            max-width: 109px;
            width: 100%;
            color: #ffffff;
            font-size: 15px;
            &:not([type="button"]) {
              margin-right: 25px;
            }
            &[type="button"] {
              color: #e23c23;
              background: #ffffff;
              border: solid 1px #e23c23;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}
.gg-auth-modal {
  max-width: 400px;
  .modal-body {
    text-align: center;
    padding-bottom: 20px !important;
    .btn-close-modal {
      background: #e23c23;
      color: #ffffff;
      padding: 5px 20px;
      border-radius: 9px;
      font-family: Montserrat-SemiBold;
      &:hover {
        background: #ffffff;
        color: #e23c23;
        border: 1px solid;
      }
    }
  }
}
