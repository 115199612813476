@keyframes mymove {
  from {
    left: 0px;
  }
  to {
    left: 100px;
  }
}

.ra-container {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  position: relative;
  &__wrapper {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
  }
  .group-img {
    display: flex;
    align-self: center;
    background: url(../images/ra-bg.svg) no-repeat top left;
    background-size: cover;
    max-width: 378px;
    max-height: 396px;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    margin-bottom: 60px;
    > img {
      position: relative;
      max-width: 270px;
      width: 100%;
      animation: mymove 2s infinite;
      animation-timing-function: linear;
    }
  }
  .description {
    text-align: center;
    font-size: 1.813rem;
    color: #414141;
    > span {
      display: inline-block;
    }
  }
  .group-btn {
    margin-top: 35px;
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 258px;
      width: 100vw;
      color: #ffffff;
      background: #00a500;
      border-radius: 9px;
      margin: 0 auto;
      font-size: 18px;

      & > span:after {
        content: "\00bb";
        position: absolute;
        opacity: 0;
        top: 0;
        right: -20px;
        transition: 0.5s;
      }

      > span {
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: 0.5s;
      }
      &:hover span {
        padding-right: 25px;
      }

      &:hover span:after {
        opacity: 1;
        right: 0;
      }
    }
  }
}
