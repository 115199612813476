form.searching {
  margin-bottom: 40px;
  justify-content: space-between;
  .search-container {
    padding: 13px 4px;
    border-radius: 15px;
    box-shadow: 0 4px 15px -5px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    max-width: 1244px;
    width: 100%;
    input,
    select {
      border-radius: 12px;
      border: solid 0.3px #9797973d;
      font-size: 15px;
      width: 100%;
      &::placeholder {
        color: #7e7e7e;
      }
    }
    .form-group {
      padding: 0 10px;
    }
  }
}

.btn-search {
  color: #ffffff;
  border-radius: 19px;
  background-color: #04a905;
  max-width: 176px;
  width: 100%;
  margin-left: 15px;
  > svg {
    margin-right: 10px;
  }
}
