.swal2-container {
  background-color: #000000ce !important;
}
.swal2-popup {
  max-width: 410px !important;
  max-height: 296px;
  width: 100% !important;
  height: 100%;
  border-radius: 15px !important;
  box-shadow: 0 2px 20px 7px rgba(0, 0, 0, 0.05);
  background: #ffffff !important;
  .swal2-header {
    .swal2-icon {
      max-width: 50px;
      max-height: 50px;
      width: 100%;
      border: none;
    }
    .swal2-error {
      box-shadow: 0 2px 10px 0 rgba(244, 53, 53, 0.5);
      background-image: linear-gradient(to bottom, #fb3535, #c41919);
      .swal2-x-mark-line {
        &-left,
        &-right {
          background: #ffffff;
          width: 1.6em;
          top: 50%;
          left: 50% !important;
        }
        &-left {
          transform: translate(-50%, -50%) rotate(45deg) !important;
        }
        &-right {
          transform: translate(-50%, -50%) rotate(-45deg) !important;
        }
      }
    }
    .swal2-success {
      [class^="swal2-success-line"] {
        z-index: 3;
      }
      &-ring {
        border: none !important;
        box-shadow: 2px 0 13px 1px rgba(69, 240, 75, 0.5);
        background-image: linear-gradient(
          to right,
          #3be053 0%,
          #00a500 72%,
          #009100 112%
        );
      }
      &-line-tip,
      &-line-long {
        background-color: #ffffff !important;
      }
      &-line-tip,
      &-circular-line-left,
      &-fix {
        left: 27% !important;
        top: 50% !important;
        width: 1em !important;
        transform: translate(-50%, -27%) rotate(45deg) !important;
      }
      &-line-long,
      &-circular-line-right {
        left: 55% !important;
        top: 42% !important;
        width: 1.75em !important;
        transform: translate(-55%, -42%) rotate(-45deg) !important;
      }
    }
    .swal2-warning {
      background: #f7971e; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to right,
        #ffd200,
        #f7971e
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to right,
        #ffd200,
        #f7971e
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      max-width: 70px;
      max-height: 70px;
      .swal2-icon-content {
        color: #ffffff;
        font-weight: 900;
      }
    }
    .swal2-title {
      color: #000000;
      font-size: 20px;
    }
  }
  .swal2-content {
    .swal2-html-container {
      font-size: 14px;
      color: #4c4c4c;
    }
  }
  .swal2-actions {
    .swal2-confirm {
      padding: 10px 50px;
      font-size: 14px;
      border-radius: 10px;
    }
  }
}
.swal2-icon-warning {
  .swal2-actions {
    .swal2-styled {
      border-radius: 9px;
      border: solid 1px #1dbd1d;
      padding: 10px;
      max-width: 100px;
      width: 100%;
      transition: all 0.35s;
      margin: 0 10px;
      background-color: #1dbd1d;
      color: #ffffff;
      font-size: 15px;
      &:hover {
        box-shadow: 0 0 5px 0 #1dbd1d;
      }
      &:active {
        background-color: #3be053;
      }
    }
    .swal2-deny {
      background: #ffffff;
      color: #f9443c;
      border: solid 1px #f9443c;
      &:hover {
        box-shadow: 0 0 5px 0 #f9443c;
        background: white !important;
      }
      &:active {
        background-color: transparent;
      }
    }
  }
}
