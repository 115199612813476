.apps-container {
  background-color: #f0f0f0;
  min-height: calc(100vh - 80px);
  &__wrapper {
    max-width: 1482px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;
  }
  .apps-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 19px;
    padding: 0 21px;
    align-items: center;
    > h5 {
      margin-bottom: 0;
      font-family: Montserrat-Bold;
    }
    .btn-custom-add {
      display: flex;
      align-items: center;
      color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 5px 21px -3px rgba(57, 108, 255, 0.69);
      background-color: #3e44d3;
      font-family: Montserrat-SemiBold;
      font-size: 17px;
      > svg {
        margin-right: 10px;
      }
    }
  }
  .apps-contain {
    display: flex;
    flex-wrap: wrap;
    .app-item {
      width: 33.33%;
      padding: 21px;
      &-wrapper {
        padding: 30px 0;
        border-radius: 40px;
        box-shadow: 2px 2px 28px 2px rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
      }
      &__head {
        padding: 0 30px;
        padding-bottom: 20px;
        border-bottom: thin solid #e6e6e6;
        > h5 {
          margin-bottom: 0;
          font-family: Montserrat-SemiBold;
          margin-bottom: 35px;
          text-transform: capitalize;
        }
        .app-description {
          display: flex;
          .app-image {
            min-width: 110px;
            > img {
              border-radius: 15px;
              max-width: 90px;
              max-height: 90px;
              width: 100%;
              height: 100%;
            }
          }
          &__content {
            p {
              margin-bottom: 0;
              font-size: 14px;
              &:not(.no-font) {
                font-family: Montserrat-SemiBold;
              }
              &:last-child {
                color: #7f7f7f;
              }
              &.no-font {
                max-height: 63px;
                height: 100vh;
                overflow-x: auto;
              }
            }
            .content-wrapper {
              &:not(:last-child) {
                margin-bottom: 10px;
              }
              &:first-child {
                > p {
                  display: inline-block;
                  &:first-child {
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
      &__body {
        .app-item__row {
          padding: 20px 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: thin solid #e6e6e6;
          > p {
            font-size: 15px;
            margin-bottom: 0;
            &.status-tag {
              border-radius: 16.5px;
              background-color: #e2f8e2;
              padding: 7px;
              text-align: center !important;
              font-family: Montserrat-SemiBold;
              color: #036e04 !important;
              max-width: 122px !important;
              width: 100%;
              &--close {
                color: #e83f3f !important;
                background-color: #ffe3e3;
              }
            }
            &:first-child {
              font-family: Montserrat-SemiBold;
              max-width: 115px;
              width: 100vw;
            }
            &:last-child {
              flex: 70%;
              text-align: right;
              color: #7f7f7f;
              max-width: 50%;
              word-break: break-all;
            }
            &.http-link {
              color: #307b9d;
              cursor: pointer;
            }
          }
        }
      }
      &__footer {
        padding: 25px 20px 0 20px;
        display: flex;
        justify-content: space-between;
        > button {
          max-width: 179px;
          width: 100%;
          border-radius: 9px;
          box-shadow: 0 8px 27px -5px rgba(16, 247, 21, 0.65);
          background-color: #1dbd1d;
          text-align: center;
          padding: 20px 0;
          font-family: Montserrat-SemiBold;
          color: #ffffff;
          font-size: 15px;
          &.btn-clear {
            color: #f9443c;
            background: #ffffff;
            border: solid 1px #f9443c;
            box-shadow: none;
            margin-left: 15px;
          }
        }
      }
    }
  }
}
