.message--error {
  font-size: 13px;
  padding-left: 5px;
  color: red;
  margin-bottom: 0;
}
.signin-container {
  display: flex;
  align-items: center;
  .signin-main {
    max-width: 410px;
    width: 100%;
    margin: 0 auto;
    &--auth-two {
      padding-bottom: 20%;
    }
    .signin__logo-wrapper {
      text-align: center;
      margin-bottom: 34px;
    }
    .signin__form-wrapper {
      padding: 28px;
      background-color: #ffffff;
      box-shadow: 0 0 60px 0px #eceaea;
      border-radius: 22px;
      .form-wrapper__title {
        font-family: Montserrat-SemiBold;
        font-size: 20px;
        font-weight: 600;
        color: #676767;
        margin-bottom: 26px;
        &__title-auth-two {
          margin-bottom: 24px;
        }
      }
      .form-wrapper__forgot-password {
        color: #4b90f5;
        font-size: 14px;
        text-align: center;
        margin-bottom: 0;
        cursor: pointer;
      }
      .btn-gg-login {
        display: flex;
        background-color: #4b90f5;
        border: none;
        border-radius: 12px;
        color: white;
        padding: 6px 7px;
        padding-right: 0;
        align-items: center;
        width: 100%;
        > span {
          font-family: Montserrat-SemiBold;
          font-size: 17px;
          width: 100%;
          text-align: center;
        }
      }
      .method-or {
        margin: 22px auto;
        text-align: center;
        color: #666666;
        font-size: 15px;
      }
      .form-master {
        input {
          background: #f1f1f1;
          border-radius: 9px;
          border: none;
          font-size: 15px;
          padding: 10px 16px;
          &::placeholder {
            color: #979797;
          }
        }
        .btn-submit-login {
          background-color: #3eaa46;
          border-radius: 13px;
          color: #ffffff;
          padding: 16px 0;
          font-size: 15px;
          width: 100%;
        }
      }
      .otp-desc {
        opacity: 0.6;
        font-size: 14px;
        margin-bottom: 15px;
      }
      .form-control-secure {
        background: #f2f2f2;
        border: none;
        padding: 10px 16px;
        border-radius: 15px;
        margin-bottom: 27px;
      }
      .btn-auth-wrapper {
        text-align: center;
        .btn-auth-two-login {
          background: #38b2e0;
          font-family: Montserrat-Medium;
          font-size: 14px;
          color: #ffffff;
          border-radius: 15px;
          max-width: 279px;
          width: 100%;
          text-align: center;
          padding: 13px 0;
        }
      }
    }
  }
}
