.home-container {
  background-color: #f0f0f0;
  min-height: calc(100vh - 80px);
  padding: 0 15px;
  &__wrapper {
    max-width: 1440px;
    width: 100%;
    padding: 50px 0;
    margin: 0 auto;
    section {
      margin-bottom: 57px;
      > h5 {
        margin-bottom: 20px;
        font-family: Montserrat-Bold;
        font-weight: 600;
        color: #000000;
      }
      .apps-contain {
        display: flex;
        flex-wrap: wrap;
        .app-item {
          display: flex;
          flex: 33.33%;
          max-width: 33.33%;
          width: 100vw;
          justify-content: flex-start;
          padding-right: 10px;
          &__wrapper {
            display: flex;
            align-items: center;
            background: #ffffff;
            padding: 11px 13px;
            border-radius: 21px;
            box-shadow: 0 4px 26px -3px rgba(0, 0, 0, 0.05);
            max-width: 423px;
            width: 100vw;
            margin-bottom: 20px;
            cursor: pointer;
            flex: 100%;
          }
          &__image {
            max-width: 70px;
            max-height: 70px;
            height: 100vh;
            width: 100%;
            border-radius: 16px;
            overflow: hidden;
            margin-right: 15px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          &__detail {
            p {
              color: #727272;
              margin-bottom: 0;
              &:first-child {
                font-size: 17px;
                font-family: Montserrat-Bold;
                font-weight: 600;
                text-transform: capitalize;
              }
              &:last-child {
                font-size: 14px;
                max-width: 238px;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }
        }
        .app-item-center {
          justify-content: center;
          padding: 0 10px;
        }
        .app-item-right {
          justify-content: flex-end;
          padding-left: 10px;
          padding-right: 0;
        }
      }
      .notices-contain {
        padding: 40px 40px 20px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0 4px 16px 1px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .notice-item {
          max-width: 418px;
          width: 100%;
          margin-bottom: 20px;
          &__image {
            width: 100%;
            margin-bottom: 20px;
            > img {
              width: 100%;
            }
          }
          &__detail {
            p {
              margin-bottom: 0;
            }
            .detail__title {
              &:first-child {
                color: #000000;
                font-size: 17px;
                font-family: Montserrat-SemiBold;
              }
              &:last-child {
                font-size: 15px;
                color: #727272;
              }
            }
          }
        }
      }
    }
  }
}
