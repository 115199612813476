@media screen and (max-width: 425px) {
  .signin-main {
    .signin__form-wrapper {
      .btn-gg-login {
        > img {
          max-width: 34px;
          max-height: 34px;
          width: 100vw;
          height: 100vh;
        }
        > span {
          font-size: 1rem !important;
        }
      }
      .btn-submit-login {
        padding: 11px 0 !important;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .signin-main {
    .signin__logo-wrapper {
      > img {
        width: 50%;
      }
    }
    .signin__form-wrapper {
      padding: 15px !important;
      .btn-gg-login {
        > img {
          max-width: 30px;
          max-height: 30px;
        }
        > span {
          font-size: 0.9rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .signin-main {
    .signin__logo-wrapper {
      > img {
        width: 60%;
      }
    }
    .method-or {
      margin: 20px 0 !important;
    }
    .signin__form-wrapper {
      .form-wrapper__title {
        font-size: 18px !important;
        margin-bottom: 20px !important;
      }
      .btn-gg-login {
        > img {
          max-width: 26px;
          max-height: 26px;
        }
        > span {
          font-size: 0.8rem !important;
        }
      }
      .btn-submit-login {
        padding: 11px 0 !important;
      }
      .form-master {
        margin-bottom: 20px;
        input,
        button {
          font-size: 0.8rem !important;
        }
      }
    }
  }
}
