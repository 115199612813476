.cbx {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    &:first-child {
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      transform: scale(1);
      vertical-align: middle;
      border: 1px solid gray;
      transition: all 0.2s ease;
      svg {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        fill: none;
        stroke: white;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(-50%, -50%, 0);
      }
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        background: #0caa26;
        display: block;
        transform: scale(0);
        opacity: 1;
        border-radius: 50%;
        transition-delay: 0.2s;
      }
    }
  }
  &:hover span:first-child {
    border-color: #0caa26;
  }
}
.inp-cbx:checked + .cbx {
  span {
    &:first-child {
      border-color: #0caa26;
      background: #0caa26;
      animation: check 0.6s ease;
      svg {
        stroke-dashoffset: 0;
      }
      &:before {
        transform: scale(2.2);
        opacity: 0;
        transition: all 0.6s ease;
      }
    }
    &:last-child {
      color: gray;
      transition: all 0.3s ease;
      &:after {
        transform: scaleX(1);
        transition: all 0.3s ease;
      }
    }
  }
}

@keyframes check {
  50% {
    transform: scale(1.2);
  }
}
