.histories-container {
  background-color: #f0f0f0;
  min-height: calc(100vh - 80px);
  &__wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;
  }
  &__body {
    > h5 {
      font-family: Montserrat-Bold;
      margin-bottom: 20px;
    }
  }
}
