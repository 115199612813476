@font-face {
  font-family: Montserrat-Medium;
  src: local(Montserrat-Medium),
    url(../fonts/webfonts/Montserrat-Medium.woff2) format("woff2"),
    url(../fonts/webfonts/Montserrat-Medium.woff) format("woff"),
    url(../fonts/ttf/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Montserrat-Regular;
  src: local(Montserrat-Regular),
    url(../fonts/webfonts/Montserrat-Regular.woff2) format("woff2"),
    url(../fonts/webfonts/Montserrat-Regular.woff) format("woff"),
    url(../fonts/ttf/Montserrat-Regular.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: local(Montserrat-SemiBold),
    url(../fonts/webfonts/Montserrat-SemiBold.woff2) format("woff2"),
    url(../fonts/webfonts/Montserrat-SemiBold.woff) format("woff"),
    url(../fonts/ttf/Montserrat-SemiBold.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Montserrat-Bold;
  src: local(Montserrat-Bold),
    url(../fonts/webfonts/Montserrat-Bold.woff2) format("woff2"),
    url(../fonts/webfonts/Montserrat-Bold.woff) format("woff"),
    url(../fonts/ttf/Montserrat-Bold.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Montserrat-Light;
  src: local(Montserrat-Light),
    url(../fonts/webfonts/Montserrat-Light.woff2) format("woff2"),
    url(../fonts/webfonts/Montserrat-Light.woff) format("woff"),
    url(../fonts/ttf/Montserrat-Light.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "PayMESan-Bold";
  src: local(PayMESan-Bold), url(../fonts/PayMESan-SVG.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "PayME San";
  src: local(PayME San), url(../fonts/PayMESan.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
