.table-custom {
  box-shadow: none !important;
  border-radius: 15px !important;
  overflow: hidden !important;
  .table-wrapper {
    width: 100%;
    overflow-x: auto;
    > table {
      tr,
      th,
      td {
        color: #666666;
        font-family: Montserrat-Medium;
      }
      .MuiTableHead-root {
        background: #04a905;
        .MuiTableRow-root {
          .MuiTableCell-root {
            color: #ffffff;
            font-family: Montserrat-SemiBold;
          }
        }
      }
      .MuiTableBody-root {
        .MuiGrid-root {
          .action {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            width: 40px;
            height: 40px;
            font-size: large;
            cursor: pointer;
            &--accept {
              background: #e2f8e2;
            }
            &--denied {
              background: #ffe3e3;
            }
          }
          .btn-custom-user-action {
            width: 94px;
            font-size: 15px;
            padding: 8px 0;
            color: #ffffff;
            background: #04a905;
            height: max-content;
            border-radius: 8px;
            &--delete {
              border-radius: 8px;
              border: solid 1px #f9443c;
              background: #ffffff;
              color: #f9443c;
            }
          }
          .btn-action-permission {
            max-width: 40px;
            max-height: 40px;
            height: 100vh;
            width: 100vw;
            cursor: pointer;
            border-radius: 8px;
            > img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
      .MuiTableFooter-root {
        .MuiTableCell-root {
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.table-user {
  .MuiTable-root {
    .MuiTableCell-root {
      padding: 15px 10px;
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        .id-table {
          font-family: Montserrat-SemiBold;
        }
        .user-info {
          > p {
            margin-bottom: 0;
            color: #666666;
            font-size: 12px;
            font-family: Montserrat-SemiBold;
            &:not(:last-child) {
              margin-bottom: 4px;
            }
            &:nth-child(1) {
              font-family: Montserrat-SemiBold;
              color: #333333;
              font-size: 16px;
              margin-bottom: 10px;
              > span {
                color: #4c4c4c;
              }
            }
          }
        }
        .user-status {
          background-color: #e2f8e2;
          color: #0a720b;
          font-family: Montserrat-SemiBold;
          padding: 10px 25px;
          border-radius: 18px;
          &--close {
            background-color: #ffe3e3;
            color: #e83f3f;
          }
        }
        .access-info {
          .access-info__row {
            display: flex;
            > span {
              &:not(:last-child) {
                margin-bottom: 5px;
              }
              &:first-child {
                font-family: Montserrat-SemiBold;
                color: #333333;
                min-width: 100px;
                max-width: 100px;
                width: 100vw;
              }
            }
            &:last-child {
              .box-silver {
                background-color: #ededed;
                padding: 7px 11px;
                border-radius: 5px;
                cursor: pointer;
                margin-bottom: 0;
                > span {
                  display: block;
                  &:last-child {
                    text-align: right;
                    margin-top: 5px;
                    color: #307b9d;
                    font-size: 12px;
                  }
                }
              }
            }
          }
          &__result {
            > span:last-child {
              font-family: Montserrat-SemiBold;
            }
            &--fail {
              color: #f9443c;
            }
            &--success {
              color: #04a905;
            }
          }
        }
      }
    }
  }
}
