@import "./font";
@import "./sign_in";
@import "./home";
@import "./custom_modal";
@import "./custom_sweetaler2";
@import "./header";
@import "./custom_table";
@import "./profile";
@import "./custom_checked";
@import "./apps.scss";
@import "./users.scss";
@import "./image_upload";
@import "./history";
@import "./search";
@import "./redirect_app";
@import "./checkbox_custom";
@import "./responsive";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat-Medium;
}

.container-master {
  height: 100vh;
  background: #ffffff;
  .header-master {
    max-height: 80px;
    height: 100%;
    width: 100%;
    padding: 0 15px;
  }
}
.signin-container {
  padding: 0 15px;
  width: 100%;
  height: 100%;
}
.profile-container,
.apps-container,
.users-container,
.histories-container {
  padding: 0 15px;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

table {
  margin-bottom: 0 !important;
}

.form-box-login {
  .logo-portal {
    margin-bottom: 34px;
  }
}

.app-list--dashboard {
  display: flex;
  padding: 22px 15px 0;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  .app__info {
    display: flex;
    align-items: center;
    box-shadow: 0 4px 26px -3px rgba(0, 0, 0, 0.05);
    background: #ffffff;
    border-radius: 21px;
    padding: 11px 12px;
    max-width: 418px;
    width: 100%;
    margin-bottom: 25px;
    cursor: pointer;
    &--image {
      max-width: 70px;
      max-height: 70px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin-right: 14px;
      > img {
        width: 100%;
      }
    }
    &--content {
      > p {
        color: #565656;
        &:first-child {
          font-size: 17px;
          font-family: Montserrat-SemiBold;
          font-weight: 600;
          margin-bottom: 5px !important;
          text-transform: capitalize;
        }
        &:last-child {
          font-size: 14px;
          max-width: 238px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
.waiting-accept {
  padding: 19px 0 22px 0;
}
.table-waiting-accept {
  margin-bottom: 53px;
}
.box-table {
  .box-table-approval {
    .table-approval {
      .header-table {
        > th {
          font-size: 18px;
          border: none;
          vertical-align: center;
        }
      }
    }
    .footer-table {
      td {
        padding: 10px;
      }
    }
  }
}

.row-notice {
  padding-bottom: 15px;
}

.box-notify {
  .notify-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 42px 45px 17px;
    &--content {
      max-width: 418px;
      width: 100%;
      margin-bottom: 25px;
      > img {
        margin-bottom: 22px;
      }
      .content__title {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 7px !important;
      }
      .content__description {
        opacity: 0.6;
        color: #000000;
      }
    }
  }
}
.top-header {
  .icon-noti,
  .icon-menu {
    cursor: pointer;
  }
}

.header-right {
  .rltive-test {
    .apps-into-menu {
      padding: 20px 20px 10px 20px;
      position: absolute;
      background: #ffffff;
      border-radius: 29px;
      right: 30px;
      top: 86px;
      max-width: 433px;
      min-width: 433px;
      width: 100%;
      box-shadow: 0 2px 27px 4px rgba(0, 0, 0, 0.08);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .app-menu-item {
        display: flex;
        flex: 45%;
        align-items: center;
        margin-bottom: 10px;
        .menu-item__image {
          max-width: 70px;
          max-height: 70px;
          width: 100%;
          > img {
            width: 100%;
          }
        }
      }
    }
  }
  .dropdown-menu {
    padding: 0;
    overflow: hidden;
    .dropdown-custom {
      .MuiList-padding {
        padding: 0;
        .MuiButtonBase-root {
          padding: 10px 20px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.account-page {
  .title-account {
    margin-bottom: 33px !important;
  }
  &-right,
  &-left {
    background: #ffffff;
    padding: 36px;
    border-radius: 25px;
    box-shadow: 0 5px 26px 6px rgba(0, 0, 0, 0.05);
  }
  &-right {
    .avatar-portal {
      max-width: 135px;
      max-height: 135px;
      width: 100%;
      height: 100%;
      margin-right: 35px;
      border-radius: 25px;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .custom-photo {
      position: relative;
      .upload-avatar {
        width: max-content;
        border-radius: 9px;
        border: solid 1px #1dbd1d;
        #file {
          display: none;
        }
        label {
          cursor: pointer;
          margin: 0;
          font-size: 15px;
          display: inline-block;
          padding: 12.5px 10.5px;
        }
      }
    }
    .form-profile {
      padding-top: 34px;
      .form-group {
        margin: 0;
        margin-bottom: 14px;
        overflow: hidden;
        padding: 11px 17px;
        > label {
          margin: 0;
          font-size: 12px;
          color: #8c8c8c;
        }
        > input,
        select {
          padding: 0;
          font-size: 18px;
          color: #383838;
          height: auto;
        }
      }
    }
    .group-button {
      padding-top: 24px;
      .btn-save-info {
        background: #1dbd1d;
        font-size: 15px;
        color: #ffffff;
        border-radius: 9px;
        padding: 18px 36.39px;
      }
    }
  }
  &-left {
    &--part-one {
      margin-bottom: 68px;
    }
  }
}
.was-validated {
  input.form-control,
  .form-select {
    &:valid {
      background-image: none;
    }
  }
  .form-check-input {
    &:valid ~ .form-check-label {
      color: initial;
    }
  }
}

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
}
.form-check-input {
  &:checked {
    background-color: #198754;
    & ~ .form-check-label {
      font-family: Montserrat-SemiBold !important;
    }
  }
  & ~ .form-check-label {
    font-family: Montserrat-Regular !important;
  }
}

.form-group {
  .react-date-picker {
    width: 100%;
    &__wrapper {
      border-radius: 11px;
      border: solid 0.3px #97979777;
      font-size: 14px;
      width: 100%;
      padding: 2.85px 0.75rem;
      margin-bottom: 5px;
    }
    &__inputGroup {
      &__input {
        border: none !important;
        outline: none;
      }
    }
    &__calendar {
      right: 0 !important;
      left: unset !important;
      width: 300px;
      .react-calendar {
        border: solid 0.3px #97979777;
        border-radius: 11px;
        overflow: hidden;
        &__navigation {
          margin-bottom: 0;
          > button {
            font-family: Montserrat-SemiBold;
            min-width: 30px;
            > span {
              font-size: 14px;
              font-family: Montserrat-Bold;
            }
          }
        }
        &__month-view__days {
          > button {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.Toastify__toast-container {
  max-width: 395px;
  width: 100vw !important;
}
