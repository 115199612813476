.header-master {
  display: flex;
  align-items: center;
  justify-content: center;
  &--wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    width: 100%;
  }
  &__logo {
    cursor: pointer;
  }
  &__panel {
    display: flex;
    align-items: center;
    .panel__notice,
    .panel__apps {
      height: max-content;
      cursor: pointer;
      user-select: none;
    }
    .panel__apps {
      margin-left: 35px;
      position: relative;
      .apps-board {
        display: flex;
        position: absolute;
        border-radius: 29px;
        box-shadow: 0 2px 27px 4px rgba(0, 0, 0, 0.08);
        background-color: #ffffff;
        flex-wrap: wrap;
        padding: 20px;
        padding-bottom: 0;
        width: 435px;
        justify-content: space-between;
        right: 0;
        top: 65px;
        z-index: 2;
        .board-item {
          display: flex;
          align-items: center;
          flex: 50%;
          margin-bottom: 20px;
          border-radius: 10px;
          padding: 10px;
          transition: all 0.5s;
          &:hover {
            background-color: rgb(177, 255, 177);
            .board-item__app-name {
              color: #f7894a;
            }
          }
          &__icon {
            max-width: 50px;
            max-height: 50px;
            width: 100%;
            height: 100%;
            border-radius: 16px;
            overflow: hidden;
            margin-right: 10px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          &__app-name {
            font-size: 15px;
            font-family: Montserrat-SemiBold;
            text-transform: capitalize;
            color: #727272;
            margin-bottom: 0;
            margin-left: 5px;
          }
        }
      }
    }
    .panel__dropdown-profile {
      position: relative;
      margin-left: 35px;
      cursor: pointer;
      user-select: none;
      .profile__box {
        display: flex;
        align-items: center;
        border-radius: 39px;
        border: solid 1px #04a905;
        padding: 4px;
        > p {
          margin-bottom: 0;
          padding: 0 20px;
          color: #565656;
          font-size: 14px;
          font-family: Montserrat-SemiBold;
        }
        &--image {
          max-width: 43px;
          max-height: 43px;
          height: 100vh;
          width: 100vw;
          border-radius: 50%;
          overflow: hidden;
          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .profile__dropdown {
        position: absolute;
        right: 0;
        top: 65px;
        border-radius: 16px;
        box-shadow: 0 5px 26px 5px rgba(0, 0, 0, 0.09);
        background-color: #ffffff;
        overflow: hidden;
        z-index: 3;
        max-width: 230px;
        width: 100vw;
        .active {
          background: rgb(170, 255, 144);
        }
        .dropdown__list {
          display: flex;
          align-items: center;
          padding: 10px 15px;
          cursor: pointer;
          color: #7f7f7f;
          text-decoration: none;
          font-size: 14px;
          font-family: Montserrat-SemiBold;
          &:hover {
            background-color: rgb(177, 255, 177);
            color: #f7894a;
          }
          > img {
            max-width: 30px;
            width: 100%;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
