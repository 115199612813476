.users-container {
  background-color: #f0f0f0;
  min-height: calc(100vh - 80px);
  &__wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;
  }
  &__contain {
    .contain__head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      h5 {
        font-family: Montserrat-Bold;
      }
    }
  }
}

.btn-custom-add-person {
  color: #ffffff;
  > svg {
    margin-right: 10px;
  }
  width: auto;
  border-radius: 10px;
  box-shadow: 0 5px 21px -3px rgba(250, 205, 31, 0.76);
  background-color: #fbc206;
}

.acl-list-app {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .acl-item {
    flex: 33.33%;
    max-width: 33.33%;
    display: flex;
    padding-right: 15px;
    &-center {
      justify-content: center;
      padding: 0 15px;
    }
    &-right {
      justify-content: flex-end;
      padding-left: 15px;
    }
    &__wrapper {
      display: flex;
      align-items: center;
      max-width: 420px;
      width: 100vw;
      padding: 11px;
      border-radius: 21px;
      box-shadow: 0 4px 26px -3px rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
      margin-bottom: 60px;
      position: relative;
      cursor: pointer;
    }

    &__contain {
      display: flex;
      align-items: center;
      > img {
        max-width: 70px;
        max-height: 70px;
        width: 100vw;
        height: 100vh;
        margin-right: 18px;
        border-radius: 16px;
      }
    }
    .acl-item-box {
      > p {
        color: #565656;
        margin-bottom: 0;
        &:first-child {
          font-family: Montserrat-Bold;
          font-size: 17px;
        }
        &:last-child {
          font-size: 14px;
          max-width: 233px;
          width: 100vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .checkbox-group {
      align-self: flex-start;
      flex: auto;
      text-align: right;
      padding: 4px 4px 0 0;
      > label {
        display: flex;
        justify-content: flex-end;
        &::before {
          position: absolute; /* being part of the label,lets stick it and let it catch the pointer-events */
          content: ""; /*make it be */
          /* size it to cover the closest relative(or absolute/fixed) parent */
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }
    }
  }
}
